<template>
    <div class="print-css">
        <div class="print-screen py-10">
            <div class="sponsors"></div>

            <p class="x-small--text">
                Please adhere this print submission summary to the front of your acid free plastic sleeve.
            </p>

            <!-- Entry QR Code -->

            <div class="print-screen-container mb-10">
                <div class="image">
                    <img :src="$fluro.asset.imageUrl(image)" />
                </div>

                <div class="information">
                    <h4>
                        Entry ID:&nbsp;<span class="normal--text">{{ entryCode }}</span>
                    </h4>
                    <h4>
                        Category:&nbsp;<span class="normal--text">{{ entryCategory }}</span>
                    </h4>
                    <h4>
                        Name:&nbsp;<span class="normal--text">{{ entryAuthor && entryAuthor.title }}</span>
                    </h4>
                    <h4>
                        Entrant Type:&nbsp;<span class="normal--text">{{ entryAuthorEntrantType }}</span>
                    </h4>
                </div>

                <div class="code">
                    <div class="sponsors"></div>

                    <div class="qr">
                        <img ref="code" />
                    </div>
                </div>
            </div>

            <!-- Entry Shipping Label -->

            <p class="x-small--text" v-if="!printer">
                Entry Shipping Label: Please attach to packaging. Prints should be posted inside a strong cardboard box
                such as the box that print paper is packaged in. Please include instructions &quot;Do not bend&quot; on
                the outside of your packaging.
            </p>

            <div class="print-screen-container mb-10" v-if="!printer">
                <div class="d-flex flex-column">
                    <p class="x-small--text">Affix postage before mailing</p>

                    <div class="d-flex align-start">
                        <div class="from">
                            <div class="sponsors"></div>

                            <h4>From:</h4>

                            <div class="small--text">
                                <p class="ma-0">{{ entryAuthor && entryAuthor.title }}</p>
                                <p class="ma-0">{{ entryAuthorAddress }}</p>
                            </div>
                        </div>

                        <div class="ml-10">
                            <h4>To:</h4>

                            <div class="small--text">
                                <p>Att:{{ orgData.data.address.name }}</p>
                                <p>{{ orgData.data.address.line1 }}</p>
                                <p>{{ orgData.data.address.line2 }}</p>
                                <p>{{ orgData.data.address.line3 }}</p>
                                <p class="ma-0">{{ orgData.data.address.line4 }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Entry Shipping Return Label -->

            <template v-if="returnPrint">
                <p class="x-small--text">Return Shipping Label: Please include inside Print Case.</p>

                <div class="print-screen-container">
                    <div class="d-flex flex-column">
                        <div class="d-flex align-start">
                            <div class="from">
                                <div class="sponsors"></div>

                                <h4>From:</h4>

                                <div class="small--text">
                                    <p>Att:{{ orgData.data.address.name }}</p>
                                    <p>{{ orgData.data.address.line1 }}</p>
                                    <p>{{ orgData.data.address.line2 }}</p>
                                    <p>{{ orgData.data.address.line3 }}</p>
                                    <p class="ma-0">{{ orgData.data.address.line4 }}</p>
                                </div>
                            </div>

                            <div class="ml-10">
                                <h4>To:</h4>

                                <div class="small--text">
                                    <p class="ma-0">{{ entryAuthor && entryAuthor.title }}</p>
                                    <p class="ma-0">{{ entryAuthorAddress }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import QRCode from "qrcode";

export default {
    name: "print-css",

    props: {
        entry: {
            type: Object,
        },
        contact: {
            type: Object,
        },
        printer: {
            type: Boolean,
        },
    },

    components: {},

    data() {
        return {};
    },

    computed: {
        orgData() {
            return this.$store.getters["org/getOrgData"];
        },
        image() {
            let entry = this.entry;
            console.log(entry);
            return entry?.data?.image?._id || entry?.data?.image || null;
        },
        returnPrint() {
            let entry = this.entry;
            return entry?.data?.returnPrint;
        },
        entryCode() {
            let entry = this.entry;
            return entry?.data?.entryCode;
        },
        entryCategory() {
            let entry = this.entry;
            return entry?.data?.category?.title;
        },
        entryAuthor() {
            let entry = this.entry;
            return entry?.managedOwners?.[0];
        },
        entryAuthorEntrantType() {
            return this.contact?.data?.entrant;
        },
        entryAuthorAddress() {
            return this.contact?.data?.address;
        },
    },

    methods: {
        async init() {
            let entry = this.entry;
            let domain = window.origin;

            let code = await QRCode.toDataURL(`${domain}/entries/${entry?._id}`);

            this.$nextTick(() => {
                if (this.$refs && this.$refs.code) {
                    this.$refs.code.src = code;
                }
            });
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss">
.print-screen {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100%;
    z-index: 999999;
    background-color: #ffffff;

    .print-screen-container {
        border: 1px solid var(--v-dark-base);
        padding: 10px;
        display: flex;
        align-items: center;

        .image {
            width: 25%;

            img {
                width: 100%;
                display: block;
            }
        }

        .information {
            padding: 10px;
            width: 50%;
        }

        .code {
            width: 25%;

            img {
                width: 100%;
            }
        }
    }

    @media print {
        display: block;
    }
}
</style>
